export const GAME_TITLE = 'GAME'

export const WIN_MESSAGES = ['Bon trebalh!', 'Increible', 'Ben hèt!']
export const GAME_COPIED_MESSAGE = 'Joc copiat en portapapers'
export const ABOUT_GAME_MESSAGE = 'Sus aguest jòc'
export const NOT_ENOUGH_LETTERS_MESSAGE = 'No i a pro letres'
export const WORD_NOT_FOUND_MESSAGE = 'Paraula no trapada'
export const CORRECT_WORD_MESSAGE = (solution: string) =>
  `Era paraula ere ${solution}`
export const ENTER_TEXT = 'Enter'
export const DELETE_TEXT = 'Borrar'
export const STATISTICS_TITLE = 'Estadistiques'
export const GUESS_DISTRIBUTION_TEXT = 'Guess Distribution'
export const NEW_WORD_TEXT = 'Naua paraula en'
export const SHARE_TEXT = 'Compartir'
export const TOTAL_TRIES_TEXT = 'Intents totaus'
export const SUCCESS_RATE_TEXT = 'Rati d\'èxit'
export const CURRENT_STREAK_TEXT = 'Seguida actuau'
export const BEST_STREAK_TEXT = 'Millor seguida'
